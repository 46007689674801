// Toggle global options
$enable-gradients: true;
$enable-shadows: true;

$men-tan:     #FCC777 !default;
$men-grn:     #396b6e !default;
$men-btn-grn: #778951 !default;
$men-gray:    #efefef !default;
$primary:       $men-grn;

// Customize some defaults
$body-color:  #000;
$body-bg:     #fff;


@import "bootstrap/scss/functions"; // Required
@import "bootstrap/scss/variables"; // Required
@import "bootstrap/scss/mixins"; // Required

@import "bootstrap/scss/root"; // Required
@import "bootstrap/scss/reboot"; // Required
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group"; // Requires forms
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/print";

/* Google Fonts - Open Sans */ 
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');

/*
*   Custom styles
*/

html {
  width: 100%;
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  background-color: #fff;
}
/*
  background-image: url('/img/mendez-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  background-attachment: fixed;
*/

.font-bold {
  font-weight: 900;
}

div#nav-menu {
  position: relative;
  top: 20px;
}

.station-listen {
  color:$men-grn;
  margin-top:1rem;
  margin-bottom:0;
}

.panel-number {
  font-size: 43px;
  font-weight: bold;
  color: #fff;
  width: 75px;
  height: 75px;
  background-color: #768a53;
  border-radius: 50%;
  padding-left: 20px;
  border: 5px solid #f5bb6f;
}

/* Audio player */ 

.audio.green-audio-player {
  width: 400px;
  min-width: 300px;
  height: 56px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, .07);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  user-select: none;
  -webkit-user-select: none;
  background-color: #fff;
  .play-pause-btn {
    display: none;
    cursor: pointer;
  }
  .spinner {
    width: 18px;
    height: 18px;
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/loading.png);
    background-size: cover;
    background-repeat: no-repeat;
    animation: spin 0.4s linear infinite;
  }
  .slider {
    flex-grow: 1;
    background-color: #D8D8D8;
    cursor: pointer;
    position: relative;
    .progress {
      background-color: #44BFA3;
      border-radius: inherit;
      position: absolute;
      pointer-events: none;
      .pin {
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background-color: #44BFA3;
        position: absolute;
        pointer-events: all;
        box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.32);
      }
    }
  }
  .controls {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 18px;
    color: #55606E;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    margin-right: 24px;
    .slider {
      margin-left: 16px;
      margin-right: 16px;
      border-radius: 2px;
      height: 4px;
      .progress {        
        width: 0;
        height: 100%;
        .pin {
          right: -8px;
          top: -6px;
        }
      }
    }
    span {
      cursor: default;
    }
  }
  .volume {
    position: relative;
    .volume-btn {
      cursor: pointer;
      &.open path {
        fill: #44BFA3;
      }
    }
    .volume-controls {
      width: 30px;
      height: 135px;
      background-color: rgba(0, 0, 0, 0.62);
      border-radius: 7px;
      position: absolute;
      left: -3px;
      bottom: 52px;
      flex-direction: column;
      align-items: center;
      display: flex;
      &.hidden {
        display: none;
      }
      .slider {
        margin-top: 12px;
        margin-bottom: 12px;
        width: 6px;
        border-radius: 3px;
        .progress {
          bottom: 0;
          height: 100%;
          width: 6px;
          .pin {
            left: -5px;
            top: -8px;
          }
        }
      }
    }    
  }
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }
  to {
    transform: rotateZ(1turn);
  }
}

#nav-menu,
#side-menu,
#ar-footer-menu {
  a.external-link {
    font-weight: normal;
    &:after {
      content: '';
      display: inline-block;
      height: 15px;
      width: 15px;
      background-image: url(/img/icons/external_link.svg);
      background-repeat: no-repeat;
      background-size: 15px;
      position: relative;
      top: 0px;
      left: 7px;
    }
  }
}  

.btn-mendez {
  color: #fff;
  background: $men-btn-grn;
  border-color: $men-btn-grn;
  font-weight: 800;
  text-align: left;
  padding: .25rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  width: auto !important;
  &:hover {
    background:$men-grn;
    border-color:$men-grn;
    color:#fff;
  }
}

.btn-grn {
  background:$men-grn;
  border-color: $men-grn;
  &:hover {
    background:$men-btn-grn;
    border-color: $men-btn-grn;
  }
}

.btn-trans {
  background:rgba(255,255,255,.75);
  border:1px solid rgba(255,255,255,.75);
  color:$men-grn;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* This is a test */ 
div#ar-menu {
  left: 10px!important;
}

.bg-gray {
  background-color: $men-gray;
}

.tool {
  position: absolute;
  right: 16px;
  top: 8px;
}
.tool1 {
  position: absolute;
  right: 32px;
  top: 8px;
}
.tool2 {
  position: absolute;
  right: 48px;
  top: 8px;
}

.scene-page h1 {
  color:$men-grn;
  font-size:1.75rem;
  font-weight:700;
}

/* Modals */ 

.modal {
  &.fade.show {
    z-index: 10000;
  }
  .modal-header {
    background-color:$men-tan;
    padding: .75rem;
    h2 {
      color:$men-grn;
      font-size: 1.25rem;
      font-weight: bold;
      position:relative;
      top:5px;
      line-height: initial;
      img {
        width: 30px;
        position: relative;
        top: -2px;
      }
    }
    .close {
      padding: 1.25rem 1rem;
      font-size: 2rem;
      opacity:1;
      &:hover {
        opacity:.75;
      }
      .button-icon {
        width:25px;
        height:25px;
      }
    }
  }
  .modal-body {
    img.example-marker {
      width:75px;
    }
  }
}

/* Modal XS */

@media (max-width:576px) {

  i.navbar-toggle-icon {
    color: white;
  }

  .navbar-toggler {
    padding: 0.5rem .75rem;
    background-color: $men-grn;
    border: 1px solid white;
    margin-right: 1rem;
    min-width: 2.75rem;
    min-height: 2.75rem;
  }

  .check--label-text {
    padding: 6px 12px;
  }

  .check--label-box {
    height: 20px;
    width: 20px;
  }

  .hidden-box:checked + .check--label {
    .check--label-box {
      background-color: #fff;
       &:after {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: 7px;
        width: 10px;
        height: 23px;
        border: solid #396B6E;
        border-width: 0 4px 4px 0;
        transform: rotate(45deg);
      }
    }
    .check--label-text {
      &:after {
        width: 85%;
      }
    }
  }

  .check--label-image {
    display: flex;
    align-self: center;
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size:40px;
    &.sh-0 {
      background-image: url('/theme/Mendez_Loc_0_xs.svg');
    }
    &.sh-1 {
      background-image: url('/theme/Mendez_Loc_1_xs.svg');
    }
    &.sh-2 {
      background-image: url('/theme/Mendez_Loc_2_xs.svg');
    }
    &.sh-3 {
      background-image: url('/theme/Mendez_Loc_3_xs.svg');
    }
    &.sh-4 {
      background-image: url('/theme/Mendez_Loc_4_xs.svg');
    }
    &.sh-5 {
      background-image: url('/theme/Mendez_Loc_5_xs.svg');
    }
    &.sh-6 {
      background-image: url('/theme/Mendez_Loc_6_xs.svg');
    }
    &.sh-7 {
      background-image: url('/theme/Mendez_Loc_7_xs.svg');
    }
  }

} /* Close modal XS */ 

header {
  background-image: url(/theme/Mendez_Header_bg.jpg);
  background-repeat: no-repeat;
  font-weight: bold;
  .navbar.navbar-mendez {
    padding: 1rem 0;
    .brand-mendez {
      padding-left: 1rem;
      font-size: 1.25rem;
      text-shadow: 1px 1px 4px #000000;
    }
  }
  .bg-mendez-menu {
    background-color: $men-tan;
    color: #000;
    padding: 0rem 1rem;
  }
}

.card {
  .card-body {
    .card-text {
      .panel-tagline {
        font-weight:900;
        margin-top:.5rem;
      }
    }
  }
}

.navbar-dark .navbar-toggler,
.navbar-dark .dropdown-menu,
.navbar-dark .dropdown-item,
.navbar-dark .nav-item,
.navbar-dark .navbar-nav .nav-link,
.navbar-dark .nav-text {
  background: $dark;
  color: #fff;
}

.navbar-text {
  padding-right: 2rem;
}

a.arrow-back {
  &:before {
    content: '';
    background-size: 15px;
    display: inline;
    height: 50px;
    background-image: url(/theme/arrow_back.svg);
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 25px;
  }
}

#sidebar {
  background-color: $men-tan;
  min-height: 90vh;
  li.nav-item a.active {
    color:#2C5254;
    text-decoration: underline!important;
  }
  a {
    color: $men-grn;
    font-weight: bold;
    &:hover {
      color:#2c5254;
    }
  }
}

footer {
  .btn-footer-lang {
    background: #396b6e;
    border-radius: 0.25rem;
    color: white;
    border: 1px solid white;
    font-weight: 900;
    margin-left: 1rem;
    padding: 0.25rem 1.5rem;
  }
  background-image: url('/theme/Blue-Background.png');
}

#ar-footer-menu {
  background-color: $men-tan;
  color: #000;
  padding: 0rem 1rem;
  li.nav-item a.active {
    color:#2C5254;
    text-decoration: underline!important;
  }
  a {
    color: $men-grn;
    font-weight: bold;
    &:hover {
      color:#2c5254;
    }
  }
}
@media (min-width:577px) {
  #ar-footer-menu {
    width: 100%;
  }

  .check--label-image {
    background-size:50px;
  }
}
  
#ar-footer-bar {
  background-image: url('/theme/Blue-Background.png');
  color: #fff;
  font-weight: bold;
  text-align: center;
  z-index: 999!important;
  justify-content: center;
  .navbar-expand {
    justify-content: center;
    ul li {
      padding: 0;
      width: 100%;
    }
  }
  a { 
    color: #fff;
    font-size: .875rem;
    font-weight: bold;
    &.nav-link {
      &:hover {
        color:white;
        text-shadow: 0px 0px 8px white;
      }
      &:before {
        content:'';
        background-size: 30px;
        display:block;
        height:35px;
      }
      &.ar-menu {
        &:before {
          background-image:url('/theme/icon_menu.svg');
          background-position: top center;
          background-repeat:no-repeat;
          background-size:20px;
          position: relative;
          top: 4px;
        }
      }
      &[aria-expanded="true"].ar-menu {
        &:before {
          background-image:url('/theme/icon_menu_close.svg') !important;
        }
      }
      &.instructions {
        &:before {
          background-image:url('/theme/icon_instructions.svg');
          background-position: top center;
          background-repeat:no-repeat;
        }
      }
      &.take-photo {
        &:before {
          background-image:url('/theme/icon_take-photo.svg');
          background-position: top center;
          background-repeat:no-repeat;
        }
      }
      &.scavenger-hunt {
        &:before {
          background-image:url('/theme/icon_scavenger_hunt.svg');
          background-position: top center;
          background-repeat:no-repeat;
        }
      }
      &.lang-select {
        &:before {
          background-image:url('/theme/icon_language.svg');
          background-position: top center;
          background-repeat:no-repeat;
        }
      }
      &.social-share {
        &:before {
          background-image:url('/theme/icon_share.svg');
          background-position: top center;
          background-repeat:no-repeat;
        }
      }
    }
  }
}

/* Checkbox */ 

.list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 500px;
  list-style: none;
  overflow: hidden;
  
  .list-item {
    margin: 0;
    padding: 0;
    position: relative;
    
    &:last-child {
      border: 0;
    }
  }
}

// hide the checkbox
.hidden-box {
  position: absolute;
  top: -9999px;
  left: -9999px;
  appearance: none;
  opacity: 0;
}

.check--label {
  font-size: 18px;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}

#scavenger-modal-main {
  .scav-intro {
    font-size:18px;
  }
  #scavenger-items {
    .check--label-box {
      margin:0 20px 0 0;
    }
  }
}


.check--label-box {
  display: flex;
  align-self: center;
  position: relative;
  margin: 0 20px;
  border: 3px solid #396B6E;
  border-radius: 4px;
  cursor: pointer;
}

.check--label-image {
  display: flex;
  align-self: center;
  position: relative;
  cursor: pointer;
  background-repeat: no-repeat;
}

.check--label-text {
  display: flex;
  align-self: center;
  position: relative;
  cursor: pointer;
  
  &:after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 7.5%;
    transform: translateY(-50%);
    transition: width 100ms ease-in-out;
  }
}

@media (min-width:577px) {

  .check--label-image {
    height: 40px;
    width: 40px;
    background-size:40px;
    &.sh-0 {
      background-image: url('/theme/Mendez_Loc_0.svg');
    }
    &.sh-1 {
      background-image: url('/theme/Mendez_Loc_1.svg');
    }
    &.sh-2 {
      background-image: url('/theme/Mendez_Loc_2.svg');
    }
    &.sh-3 {
      background-image: url('/theme/Mendez_Loc_3.svg');
    }
    &.sh-4 {
      background-image: url('/theme/Mendez_Loc_4.svg');
    }
    &.sh-5 {
      background-image: url('/theme/Mendez_Loc_5.svg');
    }
    &.sh-6 {
      background-image: url('/theme/Mendez_Loc_6.svg');
    }
    &.sh-7 {
      background-image: url('/theme/Mendez_Loc_7.svg');
    }
  }

  .check--label-text {
    padding: 12px;
  }

  .check--label-box {
    height: 30px;
    width: 30px;
  }

  // the magic
.hidden-box:checked + .check--label {
  .check--label-box {
    background-color: #fff;
     &:after {
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      left: 9px;
      width: 18px;
      height: 30px;
      border: solid #396B6E;
      border-width: 0 8px 8px 0;
      transform: rotate(45deg);
    }
  }
    .check--label-text {
      &:after {
        width: 85%;
      }
    }
  }

}

#back-to-top {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 8px;
  padding-left: 0;
  background: rgba(0,0,0,0.8);
  color: #fff;
  cursor: pointer;
  border: 0;
  border-radius: 50%;
  transition: opacity 0.2s ease-out;
  opacity: 0;
  i {
    font-size:1.5rem;
  }
  &:hover {
    background: rgba(0,0,0,0.5);
  }
  &.show {
    opacity: 1;
  }
}

@include media-breakpoint-up(sm) {
  footer .navbar-expand ul li {
    padding: 0 1rem;
    width: 9rem;
    line-height: normal;
  }
}

// leftover starter kit stuff

@import "icon-list";

// Style Bootstrap icons
.bi {
  fill: currentColor;
}
